import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, CardHeader, Container, Row} from 'reactstrap';
import Header from '../../../components/Headers/Header';
import {settingsType} from '../../../utils/data';
import GlobalSettingsForm from '../../../components/Forms/settings/GlobalSettingsForm';
import SubscribeSettingsForm from '../../../components/Forms/settings/subscribe/SubscribeSettingsForm';
import PremiumPostSettingsForm from '../../../components/Forms/settings/premiumPost/PremiumPostSettingsForm';

const Settings = () => {
  const {t} = useTranslation();
  const [currentTab, setCurrentTab] = React.useState(
    settingsType.GENERAL_SETTINGS,
  );

  return (
    <div>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="table-shadow" style={{padding: 10}}>
              <CardHeader className="border-0 table-custom-header">
                <div className="table-header-actions" />
              </CardHeader>
              <Tabs
                defaultActiveKey={currentTab}
                onSelect={(tab) => setCurrentTab(tab)}
                id="metadata-tabs"
                className="mb-3">
                <Tab
                  eventKey={settingsType.GENERAL_SETTINGS}
                  title="General Settings">
                  {currentTab === settingsType.GENERAL_SETTINGS && (
                    <GlobalSettingsForm type={settingsType.GENERAL_SETTINGS} />
                  )}
                </Tab>
                <Tab
                  eventKey={settingsType.SUBSCRIBE_SETTINGS}
                  title="Subscribe Settings">
                  {currentTab === settingsType.SUBSCRIBE_SETTINGS && (
                    <SubscribeSettingsForm
                      type={settingsType.SUBSCRIBE_SETTINGS}
                    />
                  )}
                </Tab>
                <Tab
                  eventKey={settingsType.PREMIUM_SETTINGS}
                  title="Premium Posts Settings">
                  {currentTab === settingsType.PREMIUM_SETTINGS && (
                    <PremiumPostSettingsForm
                      type={settingsType.PREMIUM_SETTINGS}
                    />
                  )}
                </Tab>
              </Tabs>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
