import React, {useEffect, useRef, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, FormGroup, Input, Label, Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
  listGlobalSettings,
  updateGlobalSettings,
  updateLimitOfFreeAds,
} from '../../../../redux/actions/settings.actions';
import SwitchSlider from '../../../Switch/SwitchSlider';
import Tables from '../../../Tables/PremiumPostsPackagesTable';

const PremiumPostSettingsForm = ({type}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {globalSettings} = useSelector((state) => state.settingsReducer);
  const isPremiumPostsOn = globalSettings.find(
    (setting) => setting.key === 'isPremiumPostsOn',
  );
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);
  const [currentTab, setCurrentTab] = React.useState('en');
  const [isPremiumPosts, setIsPremiumPosts] = useState(false);
  const [freePostsNmb, setFreePostsNmb] = useState(0);

  useEffect(() => {
    setHeight(containerRef.current.clientHeight);
  });

  useEffect(() => {
    dispatch(listGlobalSettings());
  }, []);

  useEffect(() => {
    setIsPremiumPosts(isPremiumPostsOn && parseInt(isPremiumPostsOn.value));
    setFreePostsNmb(isPremiumPostsOn && parseInt(isPremiumPostsOn.data));
  }, [globalSettings]);

  const handleClickIsSubscriptions = () => {
    dispatch(
      updateGlobalSettings({
        key: 'isPremiumPostsOn',
        value: !isPremiumPosts ? '1' : '0',
      }),
    );
    setIsPremiumPosts(!isPremiumPosts);
  };

  const updateFreeAdsLimit = async () => {
    dispatch(
      updateLimitOfFreeAds({
        key: 'isPremiumPostsOn',
        data: freePostsNmb,
      }),
    );
  };

  return (
    <Row>
      <div ref={containerRef} className="col">
        <Card style={{border: 'none', paddingTop: 10}}>
          {loading && (
            <div
              style={{
                height: height + 10,
                width: '100%',
                position: 'absolute',
                zIndex: 1000,
                background: 'rgba(255,255,255,0.8)',
                top: -10,
                left: -2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  position: 'unset',
                }}
                className="table-loader">
                <Spinner color={'info'} />
              </div>
            </div>
          )}
          <Tab.Container
            onSelect={(tab) => setCurrentTab(tab)}
            id="languages-tabs"
            defaultActiveKey="en">
            <Row>
              <Col lg={12} md={12} style={{marginRight: -25}}>
                <Nav variant="pills" className="flex-column">
                  <div className="recaptcha-row">
                    <p>{t('isPremiumPosts')}</p>
                    <SwitchSlider
                      clicked={handleClickIsSubscriptions}
                      checked={isPremiumPosts}
                      // name={}
                    />
                  </div>
                </Nav>
                <Row>
                  <Col lg={12} md={6} sm={12}>
                    <div className="recaptcha-row">
                      <p style={{marginTop: 4}}>{t('freeAdsLimit')}</p>
                      <Input
                        style={{
                          borderRadius: '0',
                          width: '10%',
                          textAlign: 'center',
                        }}
                        type="freeAds"
                        placeholder="Enter the limit of free adds"
                        value={freePostsNmb}
                        onChange={(e) => setFreePostsNmb(e.target.value)}
                      />
                      <button
                        className="form-cancel-button"
                        style={{
                          width: '10%',
                          height: 40,
                          marginRight: 15,
                          marginLeft: 15,
                        }}
                        onClick={() => updateFreeAdsLimit()}>
                        {t('update')}
                      </button>
                    </div>
                  </Col>
                </Row>
                <Tables />
              </Col>
            </Row>
          </Tab.Container>
        </Card>
      </div>
    </Row>
  );
};

export default PremiumPostSettingsForm;
