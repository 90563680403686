import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import deleteIcon from '../../assets/images/icons/table/table-delete-icon.svg';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {
  deletePaidPackage,
  editPaidPackageStatus,
  listPaidPackages,
} from '../../redux/actions/paidPackages.actions.js';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import SwitchSlider from '../Switch/SwitchSlider.js';
import { PAID_PACKAGES_TYPE } from '../../utils/data.js';
import { getImageUrl } from '../../utils/renderImage.js';
const Tables = () => {
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);

  const { paidPackages, loading, count } = useSelector(
    (state) => state.paidPackagesReducer,
  );
  const dispatch = useDispatch();

  const navigateTo = (route) => history.push(route);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deletePaidPackage(id));
    } else return;
  };

  const activeInactivePaidPackage = (id) => {
    dispatch(editPaidPackageStatus(id));
  };

  const fetchData = useCallback(
    (currentPage, postsPerPage, search) => {
      const type = PAID_PACKAGES_TYPE.SUBSCRIBE;
      dispatch(listPaidPackages(postsPerPage, currentPage, search, type));
    },
    [dispatch],
  );

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const rows = paidPackages.map((item) => [
    item?.name[lang],
    item?.duration,
    item?.price,
    <img
      alt={'Gulf Workers'}
      className=".table-sub-category-icon"
      src={getImageUrl(item?.icon, 50, 50)}
    />,
    <SwitchSlider
      clicked={() => activeInactivePaidPackage(item?._id)}
      checked={item?.status === 1}
      name={item?.id}
    />,
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={editIcon}
        onClick={() =>
          history.push({
            pathname: `/admin/settings/editSubscribePackage/${item._id}`,
            search: window.location.search,
          })
        }
      />
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={deleteIcon}
        onClick={() => deleteHandler(item?._id)}
      />
    </>,
  ]);

  return (
    <>
      <Header cardsVisible={false} minimizeBlue={true} />
      <Container className="mt--7">
        <CustomTable
          head={[
            t('name'),
            t('duration'),
            t('price'),
            t('icons'),
            t('status'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          addClickHandler={() =>
            navigateTo('/admin/settings/addSubscribePackage')
          }
          fetchData={fetchData}
        />
      </Container>
    </>
  );
};

export default Tables;
