import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import moment from 'moment';
import {paymentStatus } from '../../utils/data.js';


const InvoiceHistoryModal = (props) => {
  const { toggle, modal, data, user } = props;
  const {t} = useTranslation();

  const [showChanges, setShowChanges] = useState(false);
  const lang = 'en';
 


  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={modal}
      toggle={() => {
        toggle()
        setShowChanges(false)
      }}>
      <div className="modal-header">
        <h5 className="modal-title" style={{color: '#007bff'}} id="exampleModalLabel">
          {user ? t('userHistory') : t('invoiceHistory')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            toggle()
            setShowChanges(false)
          }}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={12} md={12} sm={12}>
            { showChanges ? <div>
              { showChanges.changes.status && <div className='history-changes'>
                <div className='history-changes-title'>{t('status')}:</div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('oldValue')}</div>: {showChanges.changes.status.old >=0 ? paymentStatus.find((i) => i?.enum === showChanges.changes.status.old).name:'-'}
                </div>
                <div className='history-changes-value'>
                  <div className='history-changes-label'>{t('newValue')}</div>:  {showChanges.changes.status.latest >=0  ? paymentStatus.find((i) => i?.enum === showChanges.changes.status.latest).name:'-'}
                </div>
              </div> } 
            </div> : <div>
              <div className='heading-row'>
                <p>{t('editBy')}</p>
                <p>{t('editTime')}</p>
                <p>{t('actions')}</p>
              </div>
              <div className='table-data-container'>
                {data.length ? data.map((e, key) => <div key={key} className='single-row'>
                  <p>{e.editedBy}</p>
                  <p>{moment(e.editedAt).format('lll')}</p>
                  <p style={{ minWidth: 100 }}>{ e.changes && <a href='#' onClick={() => setShowChanges(e)}>{t('viewChanges')}</a>}</p>
                </div>) : null}

              </div>
            </div> }
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        { showChanges && <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowChanges(false)}>
            {t('back')}
          </Button> }
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            toggle()
            setShowChanges(false)
          }}>
          {t('close')}
        </Button>
      </div>
    </Modal>
  );
};

export default InvoiceHistoryModal;
