import React, { useState } from 'react';

import Header from '../../../Headers/Header.js';
import {
  Row,
  Container,
  Form,
  Spinner,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import cogoToast from 'cogo-toast';
import { addPaidPackage } from '../../../../redux/actions/paidPackages.actions.js';
import { paidPackagesConstants } from '../../../../redux/constants/paidPackages.constants.js';
import uploadIcon from '../../../../assets/images/icons/form/upload-icon.png';
import { useTranslation } from 'react-i18next';
import { validateLanguagesObject } from '../../../../utils/index.js';
import MultiLangInput from '../../../Inputs/MultiLangInput/MultiLangInput.js';
import { PAID_PACKAGES_TYPE } from '../../../../utils/data.js';
import { imageUploader, renderImage } from '../../../../utils/imageUpload.js';


const DashboardForm = ({ history }) => {
  //redux
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.paidPackagesReducer);
  const [name, setName] = useState({
    en: '',
    ar: '',
    hi: '',
    fil: '',
    si: '',
    ne: '',
    ta: '',
  });
  const [description, setDescription] = useState({
    en: '',
    ar: '',
    hi: '',
    fil: '',
    si: '',
    ne: '',
    ta: '',
  });
  const [icon, setIcon] = useState(null);
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);
  const [productId, setProductId] = useState('');
  const [aspectRatio, setAspactRatio] = useState();

  const inputFileHandler = (e) => setIcon(e.target?.files?.[0]);

  const onChangeNameHandler = (value, lang) => {
    setName({ ...name, [lang]: value });
  };
  const onChangeDescriptionHandler = (value, lang) => {
    setDescription({ ...description, [lang]: value });
  };

  const validateForm = () =>
    validateLanguagesObject(name) &&
    validateLanguagesObject(description) &&
    price &&
    duration &&
    productId;

  const onLoad = ({ target: { offsetHeight, offsetWidth } }) => {
    setAspactRatio(offsetHeight / offsetWidth);
  };

  const submitHandler = async () => {
    if (aspectRatio === 1) {
      dispatch({
        type: paidPackagesConstants.PAID_PACKAGES_LOADING,
      });

      const type = PAID_PACKAGES_TYPE.SUBSCRIBE;

      const formData = new FormData();
      formData.append('image', icon);

      const imageUrl = await imageUploader(formData);

      const payload = {
        name,
        description,
        icon: imageUrl,
        duration,
        price,
        productId,
        type,
      };

      if (imageUrl) {
        dispatch(addPaidPackage(payload, history));
      } else {
        cogoToast.error('Something went wrong', {
          hideAfter: 3,
          position: 'top-right',
        });
      }

    } else {
      cogoToast.error('Please chose image with same width and height');
    }
  };


  const { t, i18n } = useTranslation();

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">
                {t('addSubscribePackage')}
              </h2>
              <div className="dashboard-form-body">
                <Form>
                  <MultiLangInput
                    label="Name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(value, lang) => onChangeNameHandler(value, lang)}
                    isRequired
                  />
                  <MultiLangInput
                    label="Description"
                    placeholder="Enter description"
                    value={description}
                    onChange={(value, lang) =>
                      onChangeDescriptionHandler(value, lang)
                    }
                    isRequired
                  />
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('productId')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          style={{ borderRadius: '0' }}
                          type="text"
                          placeholder="Enter product id"
                          value={productId}
                          onChange={(e) => setProductId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('duration')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          style={{ borderRadius: '0' }}
                          type="number"
                          placeholder="Enter duration"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {t('price')}
                          <sup>*</sup>{' '}
                        </Label>
                        <Input
                          style={{ borderRadius: '0' }}
                          type="number"
                          placeholder="Enter price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label for="examplePassword">{t('icons')} </Label>
                        <InputGroup>
                          <label className="form-control chooseFile">
                            {' '}
                            <Input
                              type="file"
                              accept="image/png, image/jpg, image/jpeg"
                              name="icon-upload"
                              placeholder="Ppload file"
                              onChange={inputFileHandler}>
                              {' '}
                            </Input>
                            {icon && (
                              <p className="file-input-name">{icon?.name}</p>
                            )}
                            <div className="upload-icon">
                              <img
                                alt={'upload'}
                                style={{ maxWidth: '15px' }}
                                src={uploadIcon}
                              />
                            </div>
                          </label>
                        </InputGroup>
                      </FormGroup>
                      <br />
                      {icon && (
                        <img
                          src={renderImage(icon)}
                          className="input-image"
                          alt={'gcc'}
                        />
                      )}
                      {icon && (
                        <img
                          className="aspect-ratio-image"
                          src={renderImage(icon)}
                          onLoad={onLoad}
                          alt={'gcc'}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="dashboard-form-footer">
                <button
                  className="form-cancel-button"
                  onClick={() => history.push('/admin/settings')}>
                  {t('cancel')}
                </button>
                <button
                  onClick={submitHandler}
                  className="table-header-button"
                  disabled={!validateForm() || loading}>
                  {loading ? <Spinner color={'info'} /> : t('add')}
                </button>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
