import React, {useCallback, useEffect, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';
import editIcon from '../../assets/images/icons/table/table-edit-icon.svg';
import {listCategories} from '../../redux/actions/categories.actions.js';
import {
  editQuestionFilterStatus,
  listQuestions,
  suffleQuestion,
} from '../../redux/actions/questions.actions.js';
import {questionsConstants} from '../../redux/constants/questions.constants.js';
import {replaceUrlSearchParams} from '../../utils';
import {quesTypes, userTypes} from '../../utils/data';
import {finder} from '../../utils/dataHelpers.js';
import Export from '../Buttons/Export';
import Import from '../Buttons/Import';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';
import SwitchSlider from '../Switch/SwitchSlider.js';
import qs from 'qs';

const Tables = ({history}) => {
  const dispatch = useDispatch();
  const {questions, loading, count} = useSelector(
    (state) => state.questionsReducer,
  );
  const {categories: allCategories} = useSelector(
    (state) => state.categoriesReducer,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  const [user, setUser] = useState(1);
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) => {
      if (user && categoryId)
        dispatch(
          listQuestions(
            postsPerPage,
            currentPage,
            searchKeyword,
            user,
            categoryId,
          ),
        );
    },
    [dispatch, user, categoryId],
  );

  useEffect(() => {
    let tempCategories = [];
    allCategories?.forEach((category) => {
      if (user === 3 && category.catType === 1) tempCategories.push(category);
      else if (user !== 3 && category.catType !== 1)
        tempCategories.push(category);
    });
    setCategories(tempCategories);
    let {filtersQuery} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    if (!categoryId && !filtersQuery?.categoryId) {
      setCategoryId(tempCategories[0]?._id);
    }
  }, [allCategories, user]);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const handleUserClick = (newUserId) => {
    if (user !== newUserId) {
      setUser(newUserId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user: newUserId,
          categoryId,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const handleCategoryClick = (newCategoryId) => {
    if (categoryId !== newCategoryId) {
      setCategoryId(newCategoryId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user,
          categoryId: newCategoryId,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const onEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let sourceIdx = parseInt(result.source.index);
    let destIdx = parseInt(result.destination.index);
    if (sourceIdx === destIdx) return;
    else {
      let clone = questions;
      let draggedLink = clone[sourceIdx];
      let newList = clone.slice();
      newList.splice(sourceIdx, 1);
      newList.splice(destIdx, 0, draggedLink);
      dispatch({
        type: questionsConstants.QUESTION_SUFFLE,
        payload: newList,
      });
      dispatch(
        suffleQuestion({
          from: clone[sourceIdx]._id,
          to: clone[destIdx]._id,
          postsPerPage: postsPerPage,
          currentPage: currentPage,
          categoryId: categoryId,
          userType: user,
          shuffleType: 'filters',
        }),
      );
    }
  };

  const rows = questions.map((item) => [
    item?.question[lang]?.slice(0, 100) + ' ...',
    `${finder(quesTypes, item?.questionType)?.name} ${
      finder(quesTypes, item?.questionType)?.enum
    }`,
    <SwitchSlider
      clicked={() =>
        dispatch(editQuestionFilterStatus(item?._id, !item?.featuredFilter))
      }
      checked={item?.featuredFilter}
      name={item?.id}
    />,
    item?.options?.length,
    <img
      alt={'Gulf Workers'}
      className="td-action-img"
      src={editIcon}
      onClick={() =>
        history.push({
          pathname: `/admin/filters/editFilter/${item._id}`,
          search: window.location.search,
          state: {user, categoryId},
        })
      }
    />,
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('filterText'),
            t('quesType'),
            t('status'),
            t('options'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          onDragEnd={onEnd}
          fetchData={fetchData}
          importButton={<Import path="importFilters" />}
          exportButton={
            <Export
              filename="filters"
              resource="listQuestions"
              dataType="questions"
              params={{
                filters: true,
              }}
              headers={[
                {label: 'ID', key: `_id`},
                {label: 'Question (English)', key: `question.en`},
                {label: 'Question (Arabic)', key: `question.ar`},
                {label: 'Question (Hindi)', key: `question.hi`},
                {label: 'Question (Philipine)', key: `question.fil`},
                {label: 'Question (Sinhala)', key: `question.si`},
                {label: 'Question (Nepali)', key: `question.ne`},
                {label: 'Question (Tamil)', key: `question.ta`},

                {label: 'Filter Text(English)', key: `filterText.en`},
                {label: 'Filter Text(Arabic)', key: `filterText.ar`},
                {label: 'Filter Text(Hindi)', key: `filterText.hi`},
                {label: 'Filter Text(Philipine)', key: `filterText.fil`},
                {label: 'Filter Text(Sinhala)', key: `filterText.si`},
                {label: 'Filter Text(Nepali)', key: `filterText.ne`},
                {label: 'Filter Text(Tamil)', key: `filterText.ta`},
                {
                  label: 'Question Type',
                  key: 'questionType',
                  dataConverter: (val) => `${finder(quesTypes, val)?.name}`,
                },
              ]}
            />
          }
          filters={{
            state: {user, categoryId},
            setState: ({user, categoryId}) => {
              handleUserClick(!isNaN(user) ? parseInt(user) : 1);
              handleCategoryClick(categoryId);
            },
            component: (
              <div className="cardScroll">
                <h6>User Type:</h6>
                <ScrollMenu>
                  {userTypes?.map((item, i) => (
                    <MenuItem
                      itemId={item._id}
                      title={item?.name}
                      key={item._id}
                      onClick={
                        user === item._id
                          ? null
                          : () => handleUserClick(item._id)
                      }
                      selected={user === item._id}
                      currentItem={user}
                    />
                  ))}
                </ScrollMenu>
                {user !== 4 && (
                  <>
                    <h6>Categories:</h6>
                    <ScrollMenu>
                      {categories?.map((item) => (
                        <MenuItem
                          itemId={item._id}
                          title={item?.name[lang]}
                          key={item._id}
                          onClick={
                            categoryId === item._id
                              ? null
                              : () => handleCategoryClick(item._id)
                          }
                          selected={categoryId === item._id}
                        />
                      ))}
                    </ScrollMenu>
                  </>
                )}
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;