import settings from '../../views/pages/settings';
import addPremiumPostPackage from '../../views/pages/settings/addPremiumPostPackage';
import addSubscribePackage from '../../views/pages/settings/addSubscribePackage';
import editPremiumPostPackage from '../../views/pages/settings/editPremiumPostPackage';
import editSubscribePackage from '../../views/pages/settings/editSubscribePackage';

let routes = [
  {
    path: '/admin/settings',
    name: 'Settings',
    icon: 'settings-icon',
    component: settings,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/settings/addSubscribePackage',
    name: 'addSubscribePackage',
    icon: 'settings-icon',
    component: addSubscribePackage,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  {
    path: '/admin/settings/editSubscribePackage/:id',
    name: 'editSubscribePackage',
    icon: 'settings-icon',
    component: editSubscribePackage,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  {
    path: '/admin/settings/addPremiumPostPackage',
    name: 'addPremiumPostPackage',
    icon: 'settings-icon',
    component: addPremiumPostPackage,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  {
    path: '/admin/settings/editPremiumPostPackage/:id',
    name: 'editPremiumPostPackage',
    icon: 'settings-icon',
    component: editPremiumPostPackage,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
];

export default routes;
