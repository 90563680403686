import qs from 'qs';
import React, {useCallback, useMemo, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Container} from 'reactstrap';
import historyIcon from '../../assets/images/icons/table/history-icon.png';
import eyeIcon from '../../assets/images/icons/table/table-eye-icon.svg';
import {listInvoices} from '../../redux/actions/invoices.actions.js';
import {replaceUrlSearchParams} from '../../utils/index.js';
import {userTypes, paymentStatus, paidPackagesType} from '../../utils/data.js';
import {finder} from '../../utils/dataHelpers.js';
import Export from '../Buttons/Export.js';
import CustomTable from '../CustomTable/CustomTable.js';
import Header from '../Headers/Header.js';
import MenuItem from '../horizontalScroll/MenuItem.js';
import InvoiceHistoryModal from '../Modals/InvoiceHistoryModal.js';

const Tables = ({}) => {
  const dispatch = useDispatch();
  const {invoices, loading, count} = useSelector(
    (state) => state.invoicesReducer,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [packageType, setPackageType] = useState(-1);
  const [user, setUser] = useState(-1);
  const [status, setStatus] = useState(-1);

  const history = useHistory();
  const navigateTo = (route) => history.push(route);

  const [historyData, setHistoryData] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);

  const fetchData = useCallback(
    (currentPage, postsPerPage, searchKeyword) => {
      dispatch(
        listInvoices(
          postsPerPage,
          currentPage,
          searchKeyword,
          user,
          status,
          packageType,
        ),
      );
    },
    [dispatch, user, status, packageType],
  );

  const {t, i18n} = useTranslation();
  const lang = i18n.language;

  const handleInvoiceTypeClick = (newInvoiceType) => {
    if (packageType !== newInvoiceType) {
      setPackageType(newInvoiceType);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          type: newInvoiceType,
          status,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const handleUserClick = (newUserId) => {
    if (user !== newUserId) {
      setUser(newUserId);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user: newUserId,
          status,
          type: packageType,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const handlePaymentStatusClick = (newStatus) => {
    if (status !== newStatus) {
      setStatus(newStatus);
      const query = replaceUrlSearchParams({
        filtersQuery: {
          user,
          status: newStatus,
          type: packageType,
        },
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
    }
  };

  const toggleHistoryModal = () => {
    setHistoryModal(!historyModal);
  };

  const onHistoryClick = (item) => {
    setHistoryData(item);
    toggleHistoryModal();
  };

  const search = useMemo(() => {
    const {search} = qs.parse(
      window.location.search?.split('?')[1]
        ? window.location.search?.split('?')[1]
        : 'search=',
    );
    return search;
  }, [window.location.search]);

  const rows = invoices.map((item) => [
    item?.userId?._id ? (
      <Link to={`/admin/users/viewUser/${item.userId._id}`}>
        {item.userId.userId}
      </Link>
    ) : (
      'N/A'
    ),
    item?.userId?.name,
    item?.amount ? item?.amount?.toFixed(2) : '-',
    item?.packageId?.name?.[lang],
    paidPackagesType?.find((type) => type._id == item?.type).name,
    paymentStatus?.find((status) => status._id == item?.status).name,
    item?.orderId,
    <>
      <img
        alt={'Gulf Workers'}
        className="td-action-img"
        src={eyeIcon}
        onClick={() =>
          navigateTo({
            pathname: `/admin/invoices/viewinvoice/${item._id}`,
            search: window.location.search,
          })
        }
      />
      {item.editHistory && item.editHistory.length ? (
        <img
          alt={'Gulf Workers'}
          className="td-action-img"
          src={historyIcon}
          onClick={() => onHistoryClick(item.editHistory)}
        />
      ) : null}
    </>,
  ]);

  return (
    <>
      <Header cardsVisible={false} />
      <InvoiceHistoryModal
        toggle={toggleHistoryModal}
        modal={historyModal}
        data={historyData}
      />
      <Container className="mt--7" fluid>
        <CustomTable
          head={[
            t('userId'),
            t('fullName'),
            t('amount'),
            t('packageName'),
            t('packageType'),
            t('status'),
            t('orderID'),
            t('actions'),
          ]}
          rows={rows}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          totalCount={count}
          fetchData={fetchData}
          exportButton={
            <Export
              headers={[
                {label: 'ID', key: `userId.userId`},
                {label: 'User Name', key: `userId.name`},
                {label: 'Amount', key: `amount`},
                {label: 'Package Name', key: `packageId.name.en`},
                {label: 'Order Id', key: `orderId`},
                {
                  label: 'Payment Status',
                  key: 'status',
                  dataConverter: (val) => finder(paymentStatus, val)?.name,
                },
                {
                  label: 'Package Type',
                  key: 'type',
                  dataConverter: (val) => finder(paidPackagesType, val)?.name,
                },
              ]}
              filename="invoices"
              filters={{status: status, userType: user, type: packageType}}
              resource="listInvoices"
              dataType="invoices"
              params={{search}}
            />
          }
          filters={{
            state: {user, status, packageType},
            setState: ({user, status, packageType}) => {
              handleInvoiceTypeClick(
                !isNaN(packageType) ? parseInt(packageType) : 1,
              );
              handleUserClick(!isNaN(user) ? parseInt(user) : 1);
              handlePaymentStatusClick(status);
            },
            component: (
              <div className="cardScroll">
                <h6>Package Type:</h6>
                <ScrollMenu>
                  <MenuItem
                    itemId={-1}
                    title={'All'}
                    key={-1}
                    onClick={
                      packageType === -1
                        ? null
                        : () => handleInvoiceTypeClick(-1)
                    }
                    selected={packageType === -1}
                    currentItem={packageType}
                  />
                  {paidPackagesType?.map((item) => (
                    <MenuItem
                      itemId={item._id}
                      title={item?.name}
                      key={item._id}
                      onClick={
                        packageType === item._id
                          ? null
                          : () => handleInvoiceTypeClick(item._id)
                      }
                      selected={packageType === item._id}
                      currentItem={packageType}
                    />
                  ))}
                </ScrollMenu>
                <h6>User Type:</h6>
                <ScrollMenu>
                  <MenuItem
                    itemId={-1}
                    title={'All'}
                    key={-1}
                    onClick={user === -1 ? null : () => handleUserClick(-1)}
                    selected={user === -1}
                    currentItem={user}
                  />
                  {userTypes?.map((item) => (
                    <MenuItem
                      itemId={item._id}
                      title={item?.name}
                      key={item._id}
                      onClick={
                        user === item._id
                          ? null
                          : () => handleUserClick(item._id)
                      }
                      selected={user === item._id}
                      currentItem={user}
                    />
                  ))}
                </ScrollMenu>
                {user !== 4 && (
                  <>
                    <h6>Payment Status:</h6>
                    <ScrollMenu>
                      <MenuItem
                        itemId={-1}
                        title={'All'}
                        key={-1}
                        onClick={
                          status === -1
                            ? null
                            : () => handlePaymentStatusClick(-1)
                        }
                        selected={status === -1}
                        currentItem={status}
                      />
                      {paymentStatus?.map((item) => (
                        <MenuItem
                          itemId={item._id}
                          title={item?.name}
                          key={item._id}
                          onClick={
                            status === item._id
                              ? null
                              : () => handlePaymentStatusClick(item._id)
                          }
                          selected={status === item._id}
                          currentItem={status}
                        />
                      ))}
                    </ScrollMenu>
                  </>
                )}
              </div>
            ),
          }}
        />
      </Container>
    </>
  );
};

export default Tables;
