import { API, headerSetup } from '../../services/auth';
import { adsConstants } from '../constants';
import { errorParser } from './errorParser';
import { errorAlert, successAlert, warningAlert } from '../../utils/alerts';
import { getPendingReports } from './adReports.actions';

export const listAds =
  (perPage = 4, page = 1, search = '', userId = '', adType = -1, status = 1) =>
    async (dispatch) => {
      await headerSetup();
      dispatch({ type: adsConstants.AD_LOADING });
      console.log(adType)

      try {
        const {
          data: { data },
        } = await API.get(
          `admin/v1/listAdvertisement?perPage=${perPage}&page=${page}&adType=${adType}&search=${search}&userId=${userId}&status=${status || 1}`,
        );

        if (data) {
          dispatch({
            type: adsConstants.AD_LIST_SUCCESS,
            payload: { listing: data?.listing, count: data?.count },
          });
        }
      } catch (err) {
        const parsedError = await errorParser(err);
        dispatch({
          type: adsConstants.AD_ERROR,
          payload: parsedError,
        });
        errorAlert(parsedError);
      }
    };
export const listAdsByFilter =
  (perPage = 15, page = 1, userType, categoryId = '', adType = -1, userId = '', status = 1) =>
    async (dispatch) => {
      await headerSetup();
      dispatch({ type: adsConstants.AD_LOADING });

      console.log(adType)

      try {
        const {
          data: { data },
        } = await API.get(
          `admin/v1/listAdvertisement?perPage=${perPage}&page=${page}&userType=${userType}&categoryId=${categoryId}&userId=${userId}&status=${status || 1}&adType=${adType}`,
        );

        if (data) {
          dispatch({
            type: adsConstants.AD_LIST_SUCCESS,
            payload: { listing: data?.listing, count: data?.count },
          });
        }
      } catch (err) {
        const parsedError = await errorParser(err);
        dispatch({
          type: adsConstants.AD_ERROR,
          payload: parsedError,
        });
        errorAlert(parsedError);
      }
    };

export const getSingleAd = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    const {
      data: { data },
    } = await API.get(`admin/v1/advertisementById?addId=${id}`);

    if (data) {
      dispatch({
        type: adsConstants.AD_GET_SINGLE_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const getAdHistory = (id) => async (dispatch) => {
  console.log(id, "hereeeeeeeee111111111")
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    const {
      data: { data },
    } = await API.get(`admin/v1/adHistory?addId=${id}`);

    if (data) {
      dispatch({
        type: adsConstants.AD_HISTORY_FETCH_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const addAd = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    const {
      data: { data },
    } = await API.post('admin/v1/addAdvertisement', formData);

    if (data) {
      dispatch({
        type: adsConstants.AD_ADD_SUCCESS,
        payload: data,
      });
      successAlert(`Adverstisement added successfully`);

      history.push('/admin/ads');
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editAd = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    const {
      data: { data },
    } = await API.put('admin/v1/editAdvertisement', formData);

    if (data) {
      dispatch({
        type: adsConstants.AD_EDIT_SUCCESS,
        payload: data?.data,
      });

      successAlert(`Advertisement updated successfully`);
      history.push({
        pathname: '/admin/ads',
        state: { refresh: true },
        search: window.location.search,
      });
      dispatch({
        type: adsConstants.AD_RESET_SINGLE,
      });
      dispatch(getPendingReports());
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const deleteAd = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    await API.delete(`admin/v1/deleteAdd/${id}`);

    dispatch({
      type: adsConstants.AD_DELETE_SUCCESS,
      payload: id,
    });

    dispatch(getPendingReports());

    warningAlert(`Ad~${id} deleted`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const editAdStatus = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({ type: adsConstants.AD_LOADING });

  try {
    const {
      data: { data },
    } = await API.patch(`admin/v1/activeInnactiveAdd/${id}`);

    if (data) {
      dispatch({
        type: adsConstants.AD_EDIT_SUCCESS,
        payload: data,
      });
    }
    successAlert(`Status updated for Ad~${id}`);
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: adsConstants.AD_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
