const userTypes = [
  {
    enum: 1,
    _id: 1,
    name: 'Job Owner',
  },
  {
    enum: 2,
    _id: 2,
    name: 'Worker',
  },
  {
    enum: 3,
    _id: 3,
    name: 'Services',
  },
  // {
  //   enum: 4,
  //   _id: 4,
  //   name: 'Offices',
  // },
];

export const adTypes = [
  {
    enum: -1,
    _id: -1,
    name: 'All',
  },
  {
    enum: 0,
    _id: 0,
    name: 'Free',
  },
  {
    enum: 1,
    _id: 1,
    name: 'Premium',
  },
];

const paymentStatus = [
  {
    enum: 0,
    _id: 0,
    name: 'Pending',
  },
  {
    enum: 1,
    _id: 1,
    name: 'Paid',
  },
  {
    enum: 2,
    _id: 2,
    name: 'Unpaid',
  },
  {
    enum: 3,
    _id: 3,
    name: 'Cancelled',
  },
];
export const paidPackagesType = [
  {
    enum: 0,
    _id: 0,
    name: 'Subscribe',
  },
  {
    enum: 1,
    _id: 1,
    name: 'Premium Post',
  },
];



export const PAID_PACKAGES_TYPE = {
  SUBSCRIBE: 0,
  PREMIUM_POST: 1,
};

export const userGoals = [
  {
    enum: 1,
    _id: 1,
    name: 'Looking for workers',
  },
  {
    enum: 2,
    _id: 2,
    name: 'Finding a job',
  },
  {
    enum: 3,
    _id: 3,
    name: 'Providing Services',
  },
];

export const UserTypeEnums = {
  JOB_OWNER: userTypes.find((e) => e.name === 'Job Owner').enum,
  WORKER: userTypes.find((e) => e.name === 'Worker').enum,
  SERVICES: userTypes.find((e) => e.name === 'Services').enum,
};

export const userStatuses = [
  {
    enum: 1,
    name: 'Active',
  },
  {
    enum: 2,
    name: 'Inactive',
  },
  {
    enum: 3,
    name: 'Deleted',
  },
];

export const UserStatusEnums = {
  ACTIVE: userStatuses.find((e) => e.name === 'Active').enum,
  INACTIVE: userStatuses.find((e) => e.name === 'Inactive').enum,
  DELETED: userStatuses.find((e) => e.name === 'Deleted').enum,
};

export const adStatuses = [
  {
    enum: 1,
    name: 'Active',
  },
  {
    enum: 2,
    name: 'Inactive',
  },
  {
    enum: 3,
    name: 'Deleted',
  },
];

export const AdStatusEnums = {
  ACTIVE: userStatuses.find((e) => e.name === 'Active').enum,
  INACTIVE: userStatuses.find((e) => e.name === 'Inactive').enum,
  DELETED: userStatuses.find((e) => e.name === 'Deleted').enum,
};

const quesTypes = [
  {
    enum: 4,
    _id: 4,
    name: 'Boolean',
  },
  {
    enum: 2,
    _id: 2,
    name: 'Single Choice',
  },
  {
    enum: 3,
    _id: 3,
    name: 'Mutiple Choice',
  },
  {
    enum: 5,
    _id: 5,
    name: 'Nationalities',
  },
  {
    enum: 6,
    _id: 6,
    name: 'Gulf Countries',
  },
  {
    enum: 8,
    _id: 8,
    name: 'Age Filter',
  },
  {
    enum: 7,
    _id: 7,
    name: 'Number',
  },
  { _id: 1, enum: 1, name: 'Text Input' },
];

export const QuestionTypeEnums = {
  BOOLEAN: quesTypes.find((e) => e.name === 'Boolean').enum,
  SINGLE_CHOICE: quesTypes.find((e) => e.name === 'Single Choice').enum,
  MULTIPLE_CHOICE: quesTypes.find((e) => e.name === 'Mutiple Choice').enum,
  NATIONALITY: quesTypes.find((e) => e.name === 'Nationalities').enum,
  GULF_COUNTRIES: quesTypes.find((e) => e.name === 'Gulf Countries').enum,
  NUMBER: quesTypes.find((e) => e.name === 'Number').enum,
  TEXT: quesTypes.find((e) => e.name === 'Text Input').enum,
  AGE_FILTER: quesTypes.find((e) => e.name === 'Age Filter').enum,
};

export const maritalStatus = [
  { enum: 1, name: 'Single', _id: 1 },
  { enum: 2, name: 'Married', _id: 2 },
  { enum: 3, name: 'Widow', _id: 3 },
  { enum: 4, name: 'Divorced', _id: 4 },
];
export const workerEducation = [
  { enum: 1, name: 'Under High', _id: 1 },
  { enum: 2, name: 'High School', _id: 2 },
  { enum: 3, name: 'Diploma', _id: 3 },
  { enum: 4, name: 'Bachelor', _id: 4 },
  { enum: 5, name: 'Post Graduate', _id: 5 },
];
export const workerExperience = [
  { enum: 1, name: 'No Experience', _id: 1 },
  { enum: 2, name: 'Years under 2', _id: 2 },
  { enum: 3, name: 'Years 2-5', _id: 3 },
  { enum: 4, name: 'Years 5-10', _id: 4 },
  { enum: 5, name: 'Years 10-15', _id: 5 },
  { enum: 6, name: 'Years above 15', _id: 6 },
];
export const religion = [
  { enum: 1, name: 'Muslim', _id: 1 },
  { enum: 2, name: 'Chrisitan', _id: 2 },
  { enum: 3, name: 'Hindi', _id: 3 },
  { enum: 4, name: 'Athiest', _id: 4 },
  { enum: 5, name: 'Jewish', _id: 5 },
  { enum: 6, name: 'Other', _id: 6 },
];
export const speakingLanguage = [
  { enum: 1, name: 'Arabic', _id: 1 },
  { enum: 2, name: 'English', _id: 2 },
  { enum: 3, name: 'Hindi', _id: 3 },
  { enum: 4, name: 'Philippine', _id: 4 },
  { enum: 5, name: 'French', _id: 5 },
  { enum: 6, name: 'Other', _id: 6 },
];

export const gender = [
  { enum: 1, name: 'Male', _id: 1 },
  { enum: 2, name: 'Female', _id: 2 },
];

export const adReportStatusEnum = {
  PENDING: 0,
  RESOLVED: 1,
};
export const chatReportStatusEnum = {
  PENDING: 0,
  VIEWED: 1,
};
export const adReportStatus = {
  0: 'Pending',
  1: 'Resolved',
};
export const chatReportStatus = {
  0: 'Pending',
  1: 'Viewed',
};

export { quesTypes, userTypes, paymentStatus };

export const metadataType = {
  ABOUT_APP: 'aboutApp',
  PRIVACY_POLICY: 'privacyPolicy',
  TERMS_OF_USE: 'termsOfUse',
  FAQS: 'faqs',
};

export const settingsType = {
  GENERAL_SETTINGS: 'generalSettings',
  SUBSCRIBE_SETTINGS: 'subscribeSettings',
  PREMIUM_SETTINGS: 'premiumSettings',
};

export const registeredWithEnum = {
  EMAIL: 1,
  WHATSAPP: 2,
  SMS: 3,
};

export const registeredWith = {
  [registeredWithEnum.EMAIL]: {
    name: 'Email',
    icon: 'email.png',
  },
  [registeredWithEnum.WHATSAPP]: {
    name: 'WhatsApp',
    icon: 'whatsapp.png',
  },
  [registeredWithEnum.SMS]: {
    name: 'SMS',
    icon: 'sms.png',
  },
};

export const contactUsStatusEnum = {
  ALL: 0,
  PENDING: 1,
  RESOLVED: 2,
  IGNORED: 3,
};

export const contactUsStatus = {
  [contactUsStatusEnum.ALL]: 'All',
  [contactUsStatusEnum.PENDING]: 'Pending',
  [contactUsStatusEnum.RESOLVED]: 'Resolved',
  [contactUsStatusEnum.IGNORED]: 'Ignored',
};
