export const paidPackagesConstants = {
  PAID_PACKAGES_LOADING: 'PAID_PACKAGES_LOADING',
  PAID_PACKAGES_LIST_SUCCESS: 'PAID_PACKAGES_LIST_SUCCESS',
  PAID_PACKAGES_ERROR: 'PAID_PACKAGES_ERROR',
  PAID_PACKAGES_ADD_SUCCESS: 'PAID_PACKAGES_ADD_SUCCESS',
  PAID_PACKAGES_EDIT_SUCCESS: 'PAID_PACKAGES_EDIT_SUCCESS',
  PAID_PACKAGES_RESET_SINGLE: 'PAID_PACKAGES_RESET_SINGLE',
  PAID_PACKAGES_DELETE_SUCCESS: 'PAID_PACKAGES_DELETE_SUCCESS',
  PAID_PACKAGES_SINGLE_SUCCESS: 'PAID_PACKAGES_SINGLE_SUCCESS',
  PAID_PACKAGES_GET_SINGLE_SUCCESS: 'PAID_PACKAGES_GET_SINGLE_SUCCESS',
};
