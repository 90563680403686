import {invoicesConstants} from '../constants';

const invoicesReducer = (state = {invoices: [], invoice: {}}, action) => {
  switch (action.type) {
    case invoicesConstants.INVOICES_LOADING:
      return {...state, loading: true};

    case invoicesConstants.INVOICES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoices: action.payload.listing,
        count: action.payload.count,
      };

    case invoicesConstants.INVOICES_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoices: state.invoices.map((item) =>
          item._id === action.payload?._id ? action.payload : item,
        ),
        invoice: action.payload,
      };

    case invoicesConstants.INVOICES_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        invoice: action.payload,
      };
    case invoicesConstants.INVOICES_RESET_SINGLE:
      return {
        ...state,

        invoice: {},
      };
    case 'LOGOUT':
      return {
        ...state,
        invoices: [],
        invoice: {},
        loading: false,
        error: null,
      };
    case invoicesConstants.INVOICES_ERROR:
      return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
};

export default invoicesReducer;
