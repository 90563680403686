import {API, headerSetup} from '../../services/auth';
import {errorAlert, successAlert, warningAlert} from '../../utils/alerts';
import {invoicesConstants} from '../constants';
import {errorParser} from './errorParser';

export const listInvoices =
  (
    perPage = 4,
    page = 1,
    search = '',
    user = -1,
    status = -1,
    invoiceType = -1,
  ) =>
  async (dispatch) => {
    await headerSetup();
    dispatch({type: invoicesConstants.INVOICES_LOADING});
    let url = `admin/v1/listInvoices?perPage=${perPage}&page=${page}`;
    if (invoiceType !== -1) {
      url += `&type=${invoiceType}`;
    }
    if (user !== -1) {
      url += `&userType=${user}`;
    }
    if (status !== -1) {
      url += `&status=${status}`;
    }
    if (search !== '') {
      url += `&search=${search}`;
    }

    try {
      const {
        data: {data},
      } = await API.get(url);

      if (data) {
        dispatch({
          type: invoicesConstants.INVOICES_LIST_SUCCESS,
          payload: {listing: data?.listing, count: data?.count},
        });
      }
    } catch (err) {
      const parsedError = await errorParser(err);
      dispatch({
        type: invoicesConstants.INVOICES_ERROR,
        payload: parsedError,
      });
    }
  };

export const getSingleInvoice = (id) => async (dispatch) => {
  await headerSetup();
  dispatch({type: invoicesConstants.INVOICES_LOADING});

  try {
    const {
      data: {data},
    } = await API.get(`admin/v1/getInvoice?invoiceId=${id}`);

    if (data) {
      dispatch({
        type: invoicesConstants.INVOICES_GET_SINGLE_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: invoicesConstants.INVOICES_ERROR,
      payload: parsedError,
    });
  }
};

export const updateInvoiceStatus = (formData, history) => async (dispatch) => {
  await headerSetup();
  dispatch({type: invoicesConstants.INVOICES_LOADING});

  try {
    const {
      data: {data},
    } = await API.put('admin/v1/updateInvoiceStatus', formData);

    if (data) {
      console.log(data);
      dispatch({
        type: invoicesConstants.INVOICES_EDIT_SUCCESS,
        payload: data,
      });

      successAlert(`Invoice updated successfully`);
    }
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: invoicesConstants.INVOICES_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const suffleInvoices = (formData) => async (dispatch) => {
  await headerSetup();
  const {postsPerPage, currentPage, searchKeyword, userType, shuffleType} =
    formData;
  dispatch({type: invoicesConstants.INVOICES_LOADING});
  const ShuffleData = {
    from: formData.from,
    to: formData.to,
    shuffleType,
  };

  try {
    await API.put(`admin/v1/shuffleQuestions`, ShuffleData);

    successAlert(`Rank updated for Question`);

    dispatch(
      listInvoices(
        postsPerPage,
        currentPage,
        searchKeyword,
        userType,
        !!shuffleType,
      ),
    );
  } catch (err) {
    const parsedError = await errorParser(err);

    dispatch({
      type: invoicesConstants.INVOICES_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
