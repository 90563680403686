import Invoices from '../../views/pages/invoices';
import InvoiceView from '../../views/pages/invoices/viewInvoice';
// import AdsView from '../../views/pages/ads/viewAd';
// import AdHistory from '../../views/pages/ads/adHistory';

let routes = [
  {
    path: '/admin/invoices',
    name: 'Invoices',
    icon: 'user-icon',
    component: Invoices,
    svg: true,
    layout: '/admin',
    sideBar: true,
  },
  {
    path: '/admin/invoices/viewInvoice/:id',
    name: 'View Invoice',
    icon: 'settings-icon',
    component: InvoiceView,
    svg: true,
    layout: '/admin',
    sideBar: false,
  },
  // {
  //   path: '/admin/settings/editSubscribePackage/:id',
  //   name: 'editSubscribePackage',
  //   icon: 'settings-icon',
  //   component: editSubscribePackage,
  //   svg: true,
  //   layout: '/admin',
  //   sideBar: false,
  // },
];

export default routes;
