import React, {useEffect, useRef, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {useTranslation} from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {Card, Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
  listGlobalSettings,
  updateGlobalSettings,
} from '../../../../redux/actions/settings.actions';
import SwitchSlider from '../../../Switch/SwitchSlider';
import Tables from '../../../Tables/SubscribePackagesTable';

const SubscribeSettingsForm = ({type}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {globalSettings} = useSelector((state) => state.settingsReducer);
  const isSubscriptionsOn = globalSettings.find(
    (setting) => setting.key === 'isSubscriptionsOn',
  );
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);
  const [currentTab, setCurrentTab] = React.useState('en');
  const [isSubscriptions, setIsSubscriptions] = useState(false);

  useEffect(() => {
    setHeight(containerRef.current.clientHeight);
  });

  useEffect(() => {
    dispatch(listGlobalSettings());
  }, []);

  useEffect(() => {
    setIsSubscriptions(isSubscriptionsOn && parseInt(isSubscriptionsOn.value));
  }, [globalSettings]);

  const handleClickIsSubscriptions = () => {
    dispatch(
      updateGlobalSettings({
        key: 'isSubscriptionsOn',
        value: !isSubscriptions ? '1' : '0',
      }),
    );
    setIsSubscriptions(!isSubscriptions);
  };

  return (
    <Row>
      <div ref={containerRef} className="col">
        <Card style={{border: 'none', paddingTop: 10}}>
          {loading && (
            <div
              style={{
                height: height + 10,
                width: '100%',
                position: 'absolute',
                zIndex: 1000,
                background: 'rgba(255,255,255,0.8)',
                top: -10,
                left: -2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  position: 'unset',
                }}
                className="table-loader">
                <Spinner color={'info'} />
              </div>
            </div>
          )}
          <Tab.Container
            onSelect={(tab) => setCurrentTab(tab)}
            id="languages-tabs"
            defaultActiveKey="en">
            <Row>
              <Col lg={12} md={12} style={{marginRight: -25}}>
                <Nav variant="pills" className="flex-column">
                  <div className="recaptcha-row">
                    <p>{t('isSubscriptions')}</p>
                    <SwitchSlider
                      clicked={handleClickIsSubscriptions}
                      checked={isSubscriptions}
                      // name={}
                    />
                  </div>
                </Nav>
                <Tables />
              </Col>
            </Row>
          </Tab.Container>
        </Card>
      </div>
    </Row>
  );
};

export default SubscribeSettingsForm;
