import React, {useEffect, useState} from 'react';

import Header from '../../Headers/Header.js';
import {
  Row,
  Container,
  Form,
  Spinner,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {
  quesTypes,
  userTypes,
  paymentStatus as PAYMENT_STATUS,
  paidPackagesType,
} from '../../../utils/data.js';
import {invoicesConstants} from '../../../redux/constants/invoices.constants.js';
import {
  listInvoices,
  getSingleInvoice,
  updateInvoiceStatus,
} from '../../../redux/actions/invoices.actions.js';
import {useTranslation} from 'react-i18next';
import {useParams, Link} from 'react-router-dom';

const DashboardForm = ({history}) => {
  //redux
  const dispatch = useDispatch();
  const {invoice, loading} = useSelector((state) => state.invoicesReducer);

  const from = 'invoice';
  const {id} = useParams();

  const [statusesDropdownOpen, setStatusesDropdownOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(listInvoices());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleInvoice(id));
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (!!invoice?._id) {
  //     setPaymentStatus(invoice?.status);
  //     setPaymentMethod(invoice?.paymentMethod);
  //     setPaymentAmount(invoice?.amount);
  //     setFromDate(invoice?.from);
  //     setToDate(invoice?.to);
  //     setOrderId(invoice?.orderId);
  //     setIsActive(invoice?.isActive);

  //     setUserName(invoice?.userId?.name);
  //     setPhoneNumber(invoice?.userId?.fullNumber);
  //     setEmail(invoice?.userId?.email);
  //     setUserId(invoice?.userId?.userId);
  //     setUserType(invoice?.userId?.userType);

  //     setAdId(invoice?.adId);

  //     setPackageName(invoice?.packageId?.name?.en);
  //     setPackagePrice(invoice?.packageId?.price);
  //     setPackageDuration(invoice?.packageId?.duration);
  //     setPackageType(
  //       paidPackagesType.find((i) => i?.enum === invoice?.type)?.name,
  //     );
  //   }
  // }, [invoice?.status]);

  function formatDateFromISOToDDMMYYYY(isoDateString) {
    // Parse the ISO date string to a Date object
    const date = new Date(isoDateString);

    // Extract the day, month, and year components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const submitHandler = async (statusId) => {
    dispatch({
      type: invoicesConstants.INVOICES_LOADING,
    });

    const payload = {
      invoiceId: id,
      status: statusId,
    };

    dispatch(updateInvoiceStatus(payload, history, from));
  };

  // const submitHandler = async () => {
  //     dispatch({
  //       type: subscribePackagesConstants.SUBSCRIBE_PACKAGES_LOADING,
  //     });

  //     const payload = {
  //      _id:id,
  //       name,
  //       description,
  //       duration,
  //       price
  //     };

  //     dispatch(
  //     editSubscribePackage(
  //       payload,
  //       history,
  //       from,
  //     ));

  // };

  const {t, i18n} = useTranslation();
  const userTypeObj = userTypes.find(
    (i) => i?.enum === invoice?.userId?.userType,
  );
  const paymentStatusObj = PAYMENT_STATUS.find(
    (i) => i?.enum === invoice?.status,
  );

  return (
    <>
      <Header cardsVisible={false} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <div className="dashboard-form-container">
              <h2 className="dashboard-form-header">{t('viewInvoice')}</h2>
              <div className="dashboard-form-body">
                <Form>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('invoiceId')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={id}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('orderId')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.orderId}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('isActive')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.isActive ? t('ٌYes') : t('No')}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      {/* <FormGroup>
                        <Label for="examplePassword">{t('status')} </Label>
                        <InputGroup
                          onClick={() =>
                            setStatusesDropdownOpen(!statusesDropdownOpen)
                          }>
                          <Input
                            style={{background: '#fff'}}
                            readOnly
                            placeholder={t('selectStatus')}
                            value={
                              paymentStatusObj?.name
                                ? paymentStatusObj?.name
                                : '-'
                            }
                          />
                          <InputGroupButtonDropdown
                            addonType="append"
                            isOpen={statusesDropdownOpen}
                            toggle={() =>
                              setStatusesDropdownOpen(!statusesDropdownOpen)
                            }>
                            <DropdownToggle>
                              <p>{'>'}</p>
                            </DropdownToggle>
                            <DropdownMenu>
                              {PAYMENT_STATUS?.map((item, i) => (
                                <DropdownItem
                                  key={i}
                                  onClick={() => submitHandler(item._id)}>
                                  {item?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </InputGroupButtonDropdown>
                        </InputGroup>
                      </FormGroup> */}
                      <FormGroup>
                        <Label>{t('status')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            paymentStatusObj ? paymentStatusObj?.name : '-'
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('paymentMethod')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.paymentMethod}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('amount')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            invoice?.amount ? invoice?.amount?.toFixed(2) : '-'
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('From')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            invoice?.from
                              ? formatDateFromISOToDDMMYYYY(invoice?.from)
                              : '-'
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('To')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            invoice?.to
                              ? formatDateFromISOToDDMMYYYY(invoice?.to)
                              : '-'
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    {paidPackagesType?.find((i) => i?.enum === invoice?.type)
                      ?.name === 'Premium Post' && (
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup>
                          <Label>{t('AdId')}</Label>
                          <Input
                            style={{borderRadius: '0'}}
                            type="text"
                            placeholder="-"
                            value={invoice?.adId}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('fullName')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.userId?.name}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('phoneNumber')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.userId?.fullNumber}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('emailAddress')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.userId?.email}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('userId')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.userId?.userId}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('userType')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={userTypeObj ? userTypeObj.name : '-'}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('packageName')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.packageId?.name?.en}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('price')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            invoice?.packageId?.price
                              ? invoice?.packageId?.price?.toFixed(2)
                              : '-'
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('duration')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={invoice?.packageId?.duration}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>{t('packageType')}</Label>
                        <Input
                          style={{borderRadius: '0'}}
                          type="text"
                          placeholder="-"
                          value={
                            paidPackagesType?.find(
                              (i) => i?.enum === invoice?.type,
                            )?.name
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DashboardForm;
