import {settingsConstants} from '../constants';

const settingsReducer = (
  state = {
    globalSettings: [],
  }, action) => {
  switch (action.type) {
    case settingsConstants.LIST_GLOBAL_SETTINGS_SUCCESS:
      return {...state, globalSettings: action.payload};

    case 'LOGOUT':
      return {
        ...state,
        questions: [],
        question: {},
        loading: false,
        error: null,
      };
    case settingsConstants.AREA_ERROR:
      return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
};

export default settingsReducer;
