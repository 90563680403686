import {combineReducers} from 'redux';
import auth from './auth';
import bannersReducer from './banners.reducer';
import categoriesReducer from './categories.reducer';
import subCategoriesReducer from './sub-categories.reducer';
import usersReducer from './users.reducer';
import chatsReducer from './chats.reducer';
import contactUsReducer from './contactUs.reducer';
import questionsReducer from './questions.reducer';
import notificationsReducer from './notifications.reducer';
import countriesReducer from './countries.reducer';
import adsReducer from './ads.reducer';
import paymentsReducer from './payments.reducer';
import dashboardReducer from './dashboard.reducer';
import areasReducer from './areas.reducer';
import adReportsReducer from './adReports.reducer';
import chatReportsReducer from './chatReports.reducer';
import chatBansReducer from './chatBans.reducer';
import featureNationalitiesReducer from './featureNationalities.reducer';
import settingsReducer from './settings.reducer';
import campaignsReducer from './campaigns.reducer';
import paidPackagesReducer from './paidPackages.reducer';
import invoicesReducer from './invoices.reducer';

const reducers = combineReducers({
  auth,
  bannersReducer,
  categoriesReducer,
  subCategoriesReducer,
  usersReducer,
  chatsReducer,
  contactUsReducer,
  questionsReducer,
  notificationsReducer,
  dashboardReducer,
  paymentsReducer,
  adsReducer,
  countriesReducer,
  areasReducer,
  adReportsReducer,
  chatBansReducer,
  chatReportsReducer,
  featureNationalitiesReducer,
  settingsReducer,
  campaignsReducer,
  paidPackagesReducer,
  invoicesReducer,
});

export default reducers;
