import {API, headerSetup} from '../../services/auth';
import {settingsConstants} from '../constants';
import {errorParser} from './errorParser';
import {errorAlert, successAlert} from '../../utils/alerts';

export const listGlobalSettings = () => async (dispatch) => {
  await headerSetup();
  dispatch({type: settingsConstants.SETTINGS_LOADING});

  try {
    const {
      data: {data},
    } = await API.get('admin/v1/global-settings');

    if (data) {
      dispatch({
        type: settingsConstants.LIST_GLOBAL_SETTINGS_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: settingsConstants.SETTINGS_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const updateGlobalSettings = (formData) => async (dispatch) => {
  await headerSetup();
  dispatch({type: settingsConstants.SETTINGS_LOADING});

  try {
    await API.post('admin/v1/global-settings', formData);

    successAlert('Setting updated successfully.');
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: settingsConstants.SETTINGS_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};

export const updateLimitOfFreeAds = (formData) => async (dispatch) => {
  await headerSetup();
  dispatch({type: settingsConstants.SETTINGS_LOADING});

  try {
    await API.post('admin/v1/updateLimitOfFreeAds', formData);

    successAlert('Setting updated successfully.');
  } catch (err) {
    const parsedError = await errorParser(err);
    dispatch({
      type: settingsConstants.SETTINGS_ERROR,
      payload: parsedError,
    });
    errorAlert(parsedError);
  }
};
