import {paidPackagesConstants} from '../constants';

const paidPackagesReducer = (
  state = {paidPackages: [], paidPackage: {}},
  action,
) => {
  switch (action.type) {
    case paidPackagesConstants.PAID_PACKAGES_LOADING:
      return {...state, loading: true};

    case paidPackagesConstants.PAID_PACKAGES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paidPackages: action.payload.listing,
        count: action.payload.count,
      };
    case paidPackagesConstants.PAID_PACKAGES_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paidPackages: [action.payload, ...state.paidPackages],
      };

    case paidPackagesConstants.PAID_PACKAGES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paidPackages: state.paidPackages.filter(
          (i) => i?._id !== action.payload,
        ),
      };
    case paidPackagesConstants.PAID_PACKAGES_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paidPackages: state.paidPackages.map((item) =>
          item._id === action.payload?._id ? action.payload : item,
        ),
      };

    case paidPackagesConstants.PAID_PACKAGES_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paidPackage: action.payload,
      };
    case paidPackagesConstants.PAID_PACKAGES_RESET_SINGLE:
      return {
        ...state,

        paidPackage: {},
      };

    case paidPackagesConstants.PAID_PACKAGES_ERROR:
      return {...state, loading: false, error: action.payload};
    case 'LOGOUT':
      return {
        ...state,
        paidPackages: [],
        paidPackage: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default paidPackagesReducer;
